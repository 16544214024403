import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik, FormikHelpers, useFormik } from 'formik';
import {
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Page } from 'src/components';
import { Autocomplete } from '@material-ui/lab';
import { Branch, CreateUserRequest, Role } from 'src/types';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackBar } from 'src/hooks';
import { multiBranchFeat } from 'src/constants/feature-toggle';
import { useBranchInfo } from 'src/hooks/branch/use-branch-info';
import { FileCopy as FileCopyIcon } from '@material-ui/icons';

const { actions: userActions } = slices.user;
const { actions: roleActions, selectors: roleSelectors } = slices.roles;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%',
    margin: '-33px 0'
  },
  userSpacing: {
    paddingTop: 14
  }
}));

type FormFields = {
  firstName: string;
  lastName: string;
  userName: string;
  password: string;
  confirmPassword: string;
  userRoles: Role[];
  userBranches: Branch[];
};

const CreateUserView = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const roleFieldRef = useRef(null);
  const branchFieldRef = useRef(null);
  const snackBar = useSnackBar();
  const {
    branches,
    selectedBranches,
    setSelectedBranches,
    getBranches
  } = useBranchInfo();

  const roles = useAppSelector(roleSelectors.selectRoleList);

  const [selectedRole, setSelectedRole] = useState<Role[] | null>();
  const [userRole, setUserRole] = useState('');
  const [userBranch, setUserBranch] = useState<string>('');
  const [openDialog, setOpenDialog] = useState(false);
  const [generatedCredentials, setGeneratedCredentials] = useState({
    username: '',
    password: ''
  });

  const copyLoginCredentials = (username: string, password: string) => {
    const textToCopy = `Username: ${username}\nPassword: ${password}`;
    navigator.clipboard.writeText(textToCopy);
  };

  const resetFields = () => {
    setSelectedRole(undefined);
    setUserRole('');
    setSelectedBranches([]);
    setUserBranch('');
  };

  const generateUsername = (firstName: string, lastName: string) => {
    return `${firstName.replace(/\s+/g, '').toLowerCase()}.${lastName
      .replace(/\s+/g, '')
      .toLowerCase()}`;
  };

  const generatePassword = (lastName: string) => {
    let password = '';
    password += `${lastName.replace(/\s+/g, '').toLowerCase()}1234`;
    return password;
  };

  const onSubmit = async (
    values: FormFields,
    formikHelpers: FormikHelpers<FormFields>
  ) => {
    if (!selectedRole || selectedRole.length === 0) {
      snackBar.show({ severity: 'error', message: 'Select at least one Role' });
      return;
    }

    if (multiBranchFeat && selectedBranches.length === 0) {
      snackBar.show({
        severity: 'error',
        message: 'Select at least one Branch'
      });
      return;
    }

    const roleIds: number[] = selectedRole
      ? selectedRole.map((role) => role.id ?? 0)
      : [];

    const branchIds: number[] = selectedBranches
      ? selectedBranches.map((branch) => branch.id ?? 0)
      : [];

    const autoGeneratedUsername = generateUsername(
      values.firstName,
      values.lastName
    );

    const autoGeneratedPassword = generatePassword(values.lastName);

    setGeneratedCredentials({
      username: autoGeneratedUsername,
      password: autoGeneratedPassword
    });

    setOpenDialog(true);

    const data: CreateUserRequest = {
      first_name: values.firstName,
      last_name: values.lastName,
      username: autoGeneratedUsername,
      password: autoGeneratedPassword,
      confirm_password: autoGeneratedPassword,
      role_ids: roleIds,
      branch_ids: branchIds
    };

    const response = unwrapResult(
      await dispatch(userActions.createUserThunk(data)).finally(() => {
        formikHelpers.setSubmitting(false);
        resetFields();
      })
    );

    if (response?.success) {
      snackBar.show({
        severity: 'success',
        message: response?.message || 'User created.'
      });
      formikHelpers.setValues(
        {
          firstName: '',
          lastName: '',
          userName: '',
          password: '',
          confirmPassword: '',
          userRoles: [],
          userBranches: []
        },
        false
      );
    } else {
      snackBar.show({
        severity: 'error',
        message: response?.message || 'User creation failed.'
      });
    }
  };

  useEffect(() => {
    dispatch(roleActions.getRolesThunk());
    getBranches();
  }, [dispatch, getBranches]);

  const formik = useFormik<FormFields>({
    initialValues: {
      firstName: '',
      lastName: '',
      userName: '',
      password: '',
      confirmPassword: '',
      userRoles: [],
      userBranches: []
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .trim()
        .max(255, 'First name must be at most 255 characters')
        .required('First name is required'),
      lastName: Yup.string()
        .trim()
        .max(255, 'Last name must be at most 255 characters')
        .required('Last name is required'),
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .max(15, 'Password must be at most 15 characters')
        .required('Password is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Confirm password is required'),
      userName: Yup.string().required('Username is required'),
      userRoles: Yup.array()
        .min(1, 'User role is required')
        .required('User role is required'),
      userBranches: Yup.array()
        .min(1, 'User branch is required')
        .required('User branch is required')
    }),
    onSubmit: (values) => {
      console.log(values);
    }
  });

  return (
    <Page className={classes.root} title="Create User">
      <Container
        maxWidth="sm"
        style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Formik {...formik} onSubmit={onSubmit}>
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Box mb={3} mt={3}>
                <Typography color="textPrimary" variant="h2">
                  Create new user
                </Typography>
              </Box>
              <Box display="flex" style={{ columnGap: '1em' }}>
                <TextField
                  error={Boolean(touched.firstName && errors.firstName)}
                  fullWidth
                  helperText={touched.firstName && errors.firstName}
                  label="First name"
                  margin="normal"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.lastName && errors.lastName)}
                  fullWidth
                  helperText={touched.lastName && errors.lastName}
                  label="Last name"
                  margin="normal"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  variant="outlined"
                />
              </Box>
              <Autocomplete
                className={classes.userSpacing}
                ref={roleFieldRef}
                multiple
                value={selectedRole ? selectedRole : []}
                onChange={(event, newValue) => {
                  setSelectedRole(newValue || []);
                  setUserRole(''); // Clear input after selecting an option
                }}
                inputValue={userRole}
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === 'input') {
                    setUserRole(newInputValue);
                  }
                }}
                noOptionsText={`"${userRole}" is an invalid role`}
                id="UserRole"
                options={roles}
                getOptionLabel={(option) => option?.name || ''}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={option.id}
                      label={option.name}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params} label="User Role" variant="outlined" />
                )}
              />

              {multiBranchFeat ? (
                <Box display="flex" flexDirection="column">
                  <Autocomplete
                    multiple
                    className={classes.userSpacing}
                    ref={branchFieldRef}
                    value={selectedBranches ? selectedBranches : []}
                    onChange={(event, newValue) => {
                      setSelectedBranches(newValue || []);
                    }}
                    inputValue={userBranch}
                    onInputChange={(event, newInputValue) => {
                      setUserBranch(newInputValue);
                    }}
                    noOptionsText={`"${userBranch}" is an invalid branch`}
                    id="User Branch"
                    options={branches}
                    getOptionLabel={(option) => option?.branch_name || ''}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          key={option.id}
                          label={option.branch_name}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Branch"
                        variant="outlined"
                      />
                    )}
                  />
                  <Box display="flex" justifyContent="flex-end" mt={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => setSelectedBranches(branches)}
                      style={{ marginTop: 10 }}
                    >
                      All Branches
                    </Button>
                  </Box>
                </Box>
              ) : null}
              <Box my={2}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Create User
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Container>
      <Dialog
        open={openDialog}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenDialog(false);
          }
        }}
        PaperProps={{
          style: {
            width: '300px',
            height: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
          }
        }}
      >
        <DialogTitle>Login Account Details</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            <strong>Username:</strong> {generatedCredentials.username}
          </Typography>
          <Typography variant="body1">
            <strong>Password:</strong> {generatedCredentials.password}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Tooltip title="Copy Credentials">
            <Button
              variant="outlined"
              onClick={() =>
                copyLoginCredentials(
                  generatedCredentials.username,
                  generatedCredentials.password
                )
              }
              color="primary"
              startIcon={<FileCopyIcon />}
            >
              Copy Credentials
            </Button>
          </Tooltip>
          <Button
            style={{
              color: '#ffffff',
              backgroundColor: '#0c304a',
              textAlign: 'center'
            }}
            onClick={() => setOpenDialog(false)}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default CreateUserView;
