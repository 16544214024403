import { TransferLogsFilterActionEnum } from '../enum';

export const limitPerPage = 15;
export const limitEncryptedPerPage = 9;

export const processByField = 'PROCESSED BY';

export const searchFilter = [
  TransferLogsFilterActionEnum.Product,
  TransferLogsFilterActionEnum.DrNo,
  TransferLogsFilterActionEnum.SerialNo,
  TransferLogsFilterActionEnum.StNo
];

export const stTableHeader = [
  'Status',
  'Serial No.',
  'Dr No.',
  'Category',
  'Product Name',
  'Cancelled By',
  'Cancelled Reason'
];

export const transferLogPdfFooter = [
  { name: 'PROCESSED BY', flex: 1 },
  { name: 'PREPARED BY', flex: 1 },
  { name: 'RELEASED BY', flex: 1 },
  { name: 'DELIVERED BY.', flex: 1 },
  { name: 'RECEIVED BY', flex: 1 }
];

//easier to manage pdf logs item layout using width
export const transferLogPdfHeader = [
  { name: 'DR NO.', width: '20.05%' },
  { name: 'SERIAL', width: '25%' },
  { name: 'CATEGORY', width: '10%' },
  { name: 'PRODUCT NAME', width: '45%' }
];

export const receivedLogPdfHeader = [
  { name: 'SERIAL NO.', width: '25%' },
  { name: 'PRODUCT', width: '40%' },
  { name: 'CATEGORY', width: '10%' },
  { name: 'ORIGIN', width: '10%' },
  { name: 'PROCESSED BY', width: '15%' }
];
