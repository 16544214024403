import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { stTableHeader } from '../constant';
import { TransferLogsSerialData } from 'src/redux/slices/transfer-stock-logs';
import PrintIcon from '@material-ui/icons/Print';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { TransferLogPrintDialog } from './TransferLogPDF';
import { colors } from 'src/constants';

interface Props {
  isLoading: boolean;
  isOpen: boolean;
  stNo?: string;
  stBarcode?: string;
  processedBy?: string;
  dateTransferred?: string;
  origin?: string;
  receiving?: string;
  cancelErrors: { [key: string]: string };
  transferLogsSerialData: TransferLogsSerialData[];
  handleItemsCancellation: (logIds: number[], reason: string) => void;
  setIsLoading: (val: boolean) => void;
}

const component: FC<Props> = ({
  isLoading,
  isOpen,
  stNo,
  stBarcode,
  origin,
  receiving,
  dateTransferred,
  transferLogsSerialData,
  processedBy,
  cancelErrors,
  handleItemsCancellation,
  setIsLoading
}) => {
  const [isOpenPrintDialog, setIsOpenPrintDialog] = useState<boolean>(false);
  const [selectedIDsForDelete, setSelectedIdsForDelete] = useState<any[]>([]);
  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false);
  const [cancelReason, setCancelReason] = useState<string>(''); // any band-aid for ts error
  const [showCheckbox, setShowCheckbox] = useState<boolean>(false);

  const isAllCancelledOrReceived = useMemo(() => {
    return transferLogsSerialData?.[0]
      ? transferLogsSerialData.every(
          (item) => !!item?.cancel_reason || item?.received
        )
      : true;
  }, [transferLogsSerialData]);

  const dynamicCancelLabel = useMemo(() => {
    if (showCheckbox) {
      return selectedIDsForDelete.length > 0
        ? `Cancel Selected (${selectedIDsForDelete.length})`
        : `Hide Selection`;
    }
    return `Select Items to Cancel`;
  }, [selectedIDsForDelete, showCheckbox]);

  const toggleCheckbox = useCallback(() => {
    setShowCheckbox(!showCheckbox);
  }, [showCheckbox]);

  // any band-aid for ts error
  const onClickCheckbox = useCallback(
    (id: any) => {
      let updatedIDs: number[] = [...selectedIDsForDelete];

      // if no id do nothing;
      if (!id) {
        return;
      }

      if (updatedIDs.includes(id)) {
        updatedIDs = updatedIDs.filter((serial) => serial !== id);
      } else {
        updatedIDs.push(id);
      }

      setSelectedIdsForDelete(updatedIDs);
    },
    [selectedIDsForDelete]
  );

  const onToggleConfirmation = useCallback(() => {
    setIsOpenConfirm(!isOpenConfirm);
  }, [isOpenConfirm]);

  const handleTransferClick = useCallback(() => {
    if (showCheckbox) {
      selectedIDsForDelete[0] ? onToggleConfirmation() : toggleCheckbox();
    } else {
      toggleCheckbox();
    }
  }, [
    onToggleConfirmation,
    selectedIDsForDelete,
    showCheckbox,
    toggleCheckbox
  ]);

  const onConfirm = useCallback(() => {
    if (!selectedIDsForDelete[0] || !cancelReason) {
      setIsLoading(false);
      return;
    }

    handleItemsCancellation(selectedIDsForDelete, cancelReason);
    setCancelReason('');
    setSelectedIdsForDelete([]);
    onToggleConfirmation();
  }, [
    cancelReason,
    handleItemsCancellation,
    onToggleConfirmation,
    selectedIDsForDelete,
    setIsLoading
  ]);

  // any as ts-error bandaid
  const errorBackground = useCallback(
    (id: any) => {
      return Object.keys(cancelErrors).includes(String(id))
        ? '#fdecea'
        : 'initial';
    },
    [cancelErrors]
  );

  const renderCheckboxCondition = useCallback(
    (data: TransferLogsSerialData) => {
      const isCancelled = data.cancel_reason || data.cancelled_by;
      const isReceived = data.received === 1;
      return showCheckbox && !isCancelled && !isReceived;
    },
    [showCheckbox]
  );

  return (
    <Paper style={{ marginTop: '15px', marginBottom: '15px' }}>
      {isLoading && <LinearProgress />}
      <Dialog open={isOpenConfirm}>
        <DialogTitle>
          <Typography variant="h5">Cancel Selected Items ?</Typography>
        </DialogTitle>
        <DialogContent style={{ width: '20em' }}>
          <TextField
            fullWidth
            label="Cancel Reason"
            onChange={(e) => setCancelReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={
              isLoading || selectedIDsForDelete.length < 1 || !cancelReason
            }
            variant="contained"
            color="primary"
            onClick={onConfirm}
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={onToggleConfirmation}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {isOpen && !isLoading && (
        <>
          <Box
            padding={'1rem'}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography variant="h4">{`Stock Transfer Products (${stNo})`}</Typography>
            <Box style={{ display: 'flex', columnGap: '1em' }}>
              {!isAllCancelledOrReceived ? (
                <Button
                  disabled={isLoading}
                  variant="contained"
                  color="secondary"
                  onClick={handleTransferClick}
                >
                  {dynamicCancelLabel}
                </Button>
              ) : null}
              <Button
                variant="outlined"
                color="primary"
                startIcon={<PrintIcon />}
                onClick={() => setIsOpenPrintDialog(true)}
              >
                Print
              </Button>
            </Box>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                {stTableHeader.map((header, index) => (
                  <TableCell key={index}>{header}</TableCell>
                ))}
                {showCheckbox ? <TableCell>Selected</TableCell> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {transferLogsSerialData.map((logs) => (
                <TableRow
                  key={logs?.serial_no}
                  style={{ background: errorBackground(logs?.logs_id) }}
                >
                  <TableCell>
                    {logs?.received === 1 ? (
                      <CheckCircleIcon style={{ color: colors.green[600] }} />
                    ) : (
                      <CancelIcon color="secondary" />
                    )}
                  </TableCell>
                  <TableCell>{logs?.serial_no}</TableCell>
                  <TableCell>{logs?.dr_no}</TableCell>
                  <TableCell>{logs?.category_name}</TableCell>
                  <TableCell>{logs?.product_name}</TableCell>
                  <TableCell>{logs?.cancelled_by}</TableCell>
                  <TableCell>{logs?.cancel_reason}</TableCell>
                  <TableCell>
                    {/* Can only cancel products not yet cancelled or received */}
                    {renderCheckboxCondition(logs) ? (
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Checkbox
                          checked={selectedIDsForDelete?.includes(
                            logs?.logs_id
                          )}
                          onChange={() => onClickCheckbox(logs?.logs_id)}
                        />
                      </div>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
      <TransferLogPrintDialog
        isOpen={isOpenPrintDialog}
        stNo={stNo}
        stBarcode={stBarcode}
        dateTransfer={dateTransferred}
        origin={origin}
        receiving={receiving}
        processedBy={processedBy}
        transferLogs={transferLogsSerialData || []}
        onHandleCloseDialog={() => setIsOpenPrintDialog(false)}
      />
    </Paper>
  );
};

export const TransferLogAccordion = memo(component);
